<template>
    <div class="d-flex flex-column" ref="searchContainer" :key="'mobile-' + item.updated">
        <div class="d-flex flex-1 input-group">
            <div class="form-floating" :class="{ 'disabled': item.excluded }" v-if="item.isBit">
                <select id="fieldSelect" class="form-select" v-model="item.selectedValue" @change="applyBit">
                    <option value="istrue">{{$t('Is True')}}</option>
                    <option value="isfalse">{{$t('Is False')}}</option>
                </select>

                <label for="fieldSelect">[{{item.title}}]</label>
            </div>

            <template v-else>
                <div class="d-flex form-control" :class="{ 'disabled': item.excluded, 'field-border-corner-fix border-bottom-0': !item.isNullValueOperator }">
                    [<div class="text-truncate">{{item.title}}</div>]
                </div>

                <OperatorsChooser :filterItem="item" :filterObject="filterObject" :buttonClass="{ 'border-bottom-0': !item.isNullValueOperator }"></OperatorsChooser>
            </template>

            <div class="dropdown input-group-text dots-menu p-0" :class="{ 'button-border-corner-fix': !item.isBit && !item.isNullValueOperator, 'border-bottom-0': !item.isNullValueOperator }">
                <span class="d-flex w-100 h-100 input-group-text align-items-center border-0" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots-vertical"></i>
                    
                    <ul class="dropdown-menu">
                        <li class="dropdown-item py-2" @click="toggle">
                            <span>
                                <i class="bi bi-eye-fill me-2"></i>
                                {{ item.excluded ? $t('Activate Filter Item') : $t('Deactivate Filter Item') }}
                            </span>
                        </li>
                        <li class="dropdown-item py-2" @click="reset">
                            <span><i class="bi bi-x-lg me-2"></i>{{ $t('Reset Filter Item') }}</span>
                        </li>
                        <li class="dropdown-divider"></li>
                        <li class="dropdown-item" @click="remove">
                            <span><i class="bi bi-trash-fill text-danger me-2"></i>{{ $t('Delete Filter Item') }}</span>
                        </li>
                    </ul>
                </span>
            </div>
        </div>
        
        <div class="d-flex input-group" :class="{ 'disabled': item.excluded }" v-if="!item.isBit && !item.isNullValueOperator">
            <template v-if="item.dateExpression">
                <div class="form-control text-truncate">{{item.disabledTitle}}</div>
            </template>
            
            <template v-else-if="item.operator.indexOf('between') > -1">
                <template v-if="item.isDate">
                    <ODatePicker
                        v-model="item.combinedStartValue"
                        containerClass="form-control text-truncate border-0 p-0"
                        style="border-radius:0;"
                        class="form-control rounded-top-0"
                        inputStyle="border-bottom-right-radius:0;"
                        format="Short Date"
                        :clearable="false"
                        clearableFromPicker
                        :timePicker="false"
                        :callback="applyDateBetween">
                    </ODatePicker>

                    <span class="input-group-text">-</span>
                    
                    <ODatePicker
                        v-model="item.combinedEndValue"
                        containerClass="form-control text-truncate border-0 p-0"
                        class="form-control rounded-top-0 rounded-bottom-0"
                        format="Short Date"
                        :clearable="false"
                        clearableFromPicker
                        :timePicker="false"
                        :callback="applyDateBetween">
                    </ODatePicker>
                </template>

                <template v-else>
                    <input
                        v-model="item.combinedStartValue"
                        class="form-control text-truncate rounded-top-0"
                        :placeholder="$t('Value...')"
                        @change="applySearchEnter" />
                    
                    <span class="input-group-text">-</span>
                    
                    <input
                        v-model="item.combinedEndValue"
                        class="form-control text-truncate rounded-top-0"
                        :placeholder="$t('Value...')"
                        @change="applySearchEnter" />
                </template>
            </template>

            <template v-else>
                <template v-if="item.isDate">
                    <ODatePicker
                        v-model="item.selectedValue"
                        class="form-control text-truncate border-0 p-0"
                        style="border-radius:0;"
                        inputClassName="form-control rounded-top-0"
                        inputStyle="border-bottom-right-radius:0;"
                        format="Short Date"
                        :clearable="false"
                        clearableFromPicker
                        :timePicker="false"
                        :callback="doFilter">
                    </ODatePicker>
                </template>

                <template v-else>
                    <input
                        type="text"
                        class="form-control text-truncate rounded-top-0"
                        style="height:40px;"
                        :placeholder="$t('Value...')"
                        v-model="item.selectedValue"
                        @change="applySearchEnter" />
                </template>
            </template>


            <div v-if="item.filterDropdown && (item.distinctHandler || item.isDate) && !item.isBit"
                class="input-group-text rounded-top-0 border-start-0 m-0"
                v-target:O365_filterItemDropdownModal
                @click="updateModalFieldFilterItem(columnName)">
                <i class="bi bi-funnel-fill" :class="{ 'o365-active-filter': item.applied }"></i>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject } from 'vue';
    import ODatePicker from 'o365.vue.components.DatePicker.vue';
    import OperatorsChooser from 'o365.vue.components.FilterEditor.OperatorsChooser.vue';
    import { default as useFieldFilterControls } from 'o365.vue.composables.fieldFilter.ts';

    const props = defineProps(['columnName', 'filterObject', 'searchOnEnter', 'inputClassName', 'placeHolder', 'hideColumn', 'hideDropdown','hideOperators','filterItem']);

    const { toggle, reset, remove, applySearchEnter, applyDateBetween, applyBit, doFilter } = useFieldFilterControls(props.columnName, props.filterObject);

    let item = props.filterItem??props.filterObject.getItem(props.columnName);

    const { updateModalFieldFilterItem } = inject('O365_modalFieldFilterItem', {});
</script>

<style>
    .field-border-corner-fix {
        border-bottom-left-radius: 0;
    }

    .button-border-corner-fix {
        border-bottom-right-radius: 0;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    /* Override transform to align the dropdown to edge of entire button instead of the split trigger button only */
    .dropdown-toggle-split + .dropdown-menu {
        transform: translate(0px, -36px) !important;
    }
</style>
